<template>
  <div
    id="case-index-container"
    fluid
    class="pl-3 pt-3"
  >
    <Loader v-if="loaderState" />
    <v-card
      v-else
      class="py-6 px-6"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-row justify-space-between">
        <div
          class="d-flex flex-row align-center pointer"
          @click="handleBackClick"
        >
          <v-icon color="black">
            mdi-arrow-left
          </v-icon>
          <h2 class="d-flex-inline justify-center align-center ml-2 ma-0 font-weight-bold">
            {{ name }}
          </h2>
          <div
            v-if="executionsCount"
            class="d-flex flex-row align-center justify-space-between ml-6"
          >
            <ProgressBar
              :executions="executionsProgress"
              :percentage="executionsPercentage"
              :caseCount="executionsCount"
            />
          </div>
        </div>
        <div class="d-flex flex-row">
          <v-btn
            color="#F2F4F7"
            class="text-capitalize btn-theme rounded-lg mr-3"
            height="40px"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '92px'"
            :loading="isRerunLoading"
            :class="{ 'disabled-action': isRerunLoading || isProjectArchived }"
            
            depressed
            @click="duplicateTestRun"
          >
            {{ $t('testruns.rerun') }}
          </v-btn>
          <v-btn
            depressed
            color="primary"  
            height="40px"
            class="text-capitalize btn-theme rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '118px'"
            :loading="isCompleteRunLoading"
            :class="{ 'disabled-action': isCompleteRunLoading || isProjectArchived }"
            @click="completeTestRun"
          >
            {{ $t('testruns.complete') }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <ExecutionManagement
      v-if="!loaderState"
      :show-create="false"
      :execution="selectedExecution"
      :executions="testRunExecutions"
      :assignees="assignees"
      :test-results="testResults"
      :folders="folders"
      @bulkRemove="onBulkRemove"
      @getExecution="getExecution"
      @folder-select="getFolder"
      @updateExecution="updateTestExecution"
      @updateExecutions="updateTestExecutions"
      @updateSelectedExecution="updateSelectedExecution"
      @updateResult="updateTestResult"
      @deleteResult="deleteTestResult"
      @deleteExecution="deleteExecution"
      @addResult="addTestResult"
      @moveItem="moveSelectedItem"
    />
  </div>
</template>

<script>
// import SectionHeader from '@/components/TestCases/SectionHeader.vue';
import ExecutionManagement from '@/components/Execution/ExecutionManagement'
import { mapActions, mapGetters } from 'vuex';
import makeRunService from '@/services/api/run';
import makeExecutionService from '@/services/api/execution'
import makeResultService from '@/services/api/result'
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { dateMixin } from '@/mixins/date.js';
import makeFoldersService from '@/services/api/folder';
import handleLoading from '@/mixins/loader.js'
import Loader from '@/components/base/Loader'
import projectStatus from '@/mixins/projectStatus';
import ProgressBar from '@/components/base/ProgressBar.vue';

export default {
  components:{
    ExecutionManagement,
    Loader,
    ProgressBar
  },
  mixins: [dateMixin, handleLoading, projectStatus],
  props: {
    CustomCases : Array,
    name: String
  },
  data() {
    return {
      actionMenu: false,
      selectedCases: [],
      testRunExecutions: [],
      executionsPercentage: 0,
      executionsProgress: {},
      folderId: null,
      selectedExecution: null,
      assignees: [],
      testResults: [],
      folders: [],
      isRerunLoading: false,
      isCompleteRunLoading: false,
    };
  },
  computed:{
    ...mapGetters({
      user: 'user/user'
    }),
    executionsCount(){
      return this.testRunExecutions?.length
    },
  },
  methods: {
    ...mapActions({
        updateTestRuns:'run/updateTestRuns',
        uploadToServer: 'attachment/uploadToServer',
      }
    ),
    handleBackClick() {
      //this.showConfirmBackDialog = true;
      this.$router.replace({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    async getFolders(handle, projectKey) {
      const folderService = makeFoldersService(this.$api);
      await folderService.getProjectFolders(handle, projectKey).then(response => {
        this.folders = response.data.folders;
      }).catch(() => {
        showErrorToast(this.$swal, this.$t("test_folder.refresh_failed"));
      })
    },
    completeTestRun() {
      let payload = this.selectedCases.map(({externalId,customFields, source, uid, name }) => ({externalId: externalId,customFields, source, uid, name, completed: true}));
      this.isCompleteRunLoading = true;
      this.updateTestRuns({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: null,
        payload: { runs: payload }
      })
        .then(() => {
          this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
        })
        .catch(error => {
          console.error("Failed to Update Test Run:", error);
        }).finally(() => {
          this.isCompleteRunLoading = false;
        });
    },
    async duplicateTestRun(){
      const runService = makeRunService(this.$api);

      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const runId = this.$route.params.id;
      this.isRerunLoading = true;
      await runService.duplicateTestRun(handle, projectKey, runId).then(response => {
        showSuccessToast(this.$swal, this.$t('testruns.duplicate_testrun.success'))
        this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('testruns.duplicate_testrun.error'))
      }).finally(()=> {
        this.isRerunLoading = false;
      })
    },
    async getTestRunExecutions(handle, projectKey, runId){
      const runService = makeRunService(this.$api);
      await runService.getTestRunExecutions(
        handle,
        projectKey
        ,runId
      ).then(response => {
        this.testRunExecutions = response.data.executions;
        this.executionsPercentage = response.data.percentage;
        this.executionsProgress = response.data.executionsProgress;
        this.assignees = response.data.assignees;
        const currentUser = {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          handle: this.user.handle,
          uid: this.user.uid
        }
        this.assignees.push(currentUser)
      })
    },
    async getFolder(folderId){
      this.folderId = folderId;
    },
    async getExecution(executionId){
      await this.getExecutionResult(executionId);
      const selectedExecution = this.testRunExecutions.find(element => element.uid == executionId);
      const updatedAt = this.formatDate(selectedExecution.updatedAt, 'MM/DD/YY HH:mm:ss');
      this.selectedExecution = {
        ...selectedExecution,
        updatedAt
      };
    },
    updateSelectedExecution(selectedCases) {
      this.selectedCases = selectedCases;
    },
    async getExecutionResult(executionUid){
      const handle = this.$route.params.handle;
      const resultService = makeResultService(this.$api);
      const projectKey = this.$route.params.key; 

      this.testResults = await resultService.getTestResults(handle, projectKey, executionUid).then(async response => {
        return response.data   
      }) 
    },
    onBulkRemove(payload){
      console.log("Delete from Test Run", payload)
    },
    async updateTestExecution(updatedData){
      const executionService = makeExecutionService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution?.uid;
      let payload = {};
      
      if(typeof(updatedData.value) == 'object')
        Object.assign(payload, {
          [updatedData.property] : updatedData.value.uid
        })
      else
        Object.assign(payload, {
          [updatedData.property] : updatedData.value
        })

      const previousAssignedTo = this.selectedExecution?.assignedTo;
      
      await executionService.updateExecution(handle,projectKey,selectedExecution,payload).then((response) => {
        const execution = response.data;
        if(execution.uid){
          const findIndex = this.testRunExecutions.findIndex(element => element.uid == execution.uid);
          this.selectedExecution = {...this.selectedExecution, ...execution};
          this.testRunExecutions.splice(findIndex, 1, {...this.selectedExecution, ...execution})     
        }
        if (
          updatedData.property === 'assignedTo' &&
          previousAssignedTo !== execution.assignedTo
        ) {
          showSuccessToast(this.$swal, this.$t('success.testCaseAssigned'));
        } else {
          showSuccessToast(
            this.$swal,
            this.$t('success.executionUpdated')
          );
        }
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      })
    },
    async updateTestExecutions(data){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const executionService = makeExecutionService(this.$api);
      const payload = {
        executionIds: this.selectedCases.map(item => item.uid),
        ...data
      }
      await executionService.updateExecutions(handle, projectKey, payload).then((response) => {
        if(response.data.length)
          response.data.forEach(element => {
            const eIndex = this.testRunExecutions.findIndex(obj => obj.uid == element.uid);
            this.testRunExecutions.splice(eIndex, 1, {...this.testRunExecutions[eIndex], ...element})
          })  
        showSuccessToast(this.$swal, this.$t('success.executionUpdated'))
      }).catch((err) => {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      })
      
    },
    async deleteExecution(id){
      const handle = this.$route.params.handle
      const projectKey = this.$route.params.key
      const executionService = makeExecutionService(this.$api);
      
      await executionService.deleteExecutions(handle, projectKey, id).then(() => {
        this.testRunExecutions = this.testRunExecutions.filter(element => element.uid !== id)
        showSuccessToast(this.$swal, this.$t('success.executionDeleted'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionDeletedFailed'))
      })
      
    },
    async moveSelectedItem(direction){
      let itemIndex = this.testRunExecutions.findIndex(element => element.uid == this.selectedExecution.uid)
      if(direction == 'next' && itemIndex < this.testRunExecutions.length-1)
        this.selectedExecution = this.testRunExecutions[itemIndex + 1]
      else if(direction == 'previous' && itemIndex > 0)
        this.selectedExecution = this.testRunExecutions[itemIndex - 1]

      await this.getExecutionResult(this.selectedExecution.uid)
    },
    async addTestResult(data){
      const resultService = makeResultService(this.$api);
      const relatedTo = 'result';
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution.uid;

      const payload = {
        status: data.status,
        comment: data.comment
      };

      await resultService.addTestResult(handle, projectKey, selectedExecution, payload).then(async (response) => {
        const result = response.data;
        const mediaType = 'attachment'
        if(data.files.length)
         await Promise.all(data.files.map(async (file) => {
          await this.uploadToServer( {handle, mediaType ,relatedTo, relatedToUid:result.uid, file });
        })).then(() => {
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
        })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        
        this.selectedExecution = {...this.selectedExecution, ...{updatedAt : result.updatedAt}}
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
      })
      
      await this.getExecutionResult(selectedExecution)
    },
    async updateTestResult(resultUid,data){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const relatedTo = 'result';
      const projectKey = this.$route.params.key;

      const payload = {
        status: data.status,
        comment: data.comment
      }
      
      await resultService.updateTestResult(handle, projectKey, resultUid, payload).then(async () => {
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults[resultIndex].status = payload.status[0].toUpperCase()+payload.status.slice(1)
        this.testResults[resultIndex].comment = payload.comment;
        const mediaType = 'attachment'
        if(data.files.length)
          await Promise.all(data.files.map(async (file) => {
            await this.uploadToServer({handle, mediaType, relatedTo, relatedToUid: resultUid, file});
          })).then(() => {
            showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
          }).catch(() => {
            showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
          })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
      })
    },
    async deleteTestResult(resultUid){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      
      await resultService.deleteTestResult(handle, projectKey, resultUid).then(() => {
        showSuccessToast(this.$swal, this.$t('success.testResultDeleted'));
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults.splice(resultIndex,1)
      }).catch( () => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteTestResult'))
      } )
    }
  },
  async created(){
    const handle = this.$route.params.handle;
    const projectKey = this.$route.params.key;
    const id = this.$route.params.id;

    await this.init([
      this.getTestRunExecutions(handle,projectKey,id),
      this.getFolders(handle, projectKey),
    ])
  },
};
</script>
<style scoped>
.progress-linear{
  width: 100%;
  display: flex;
  align-items: center;
}
.progress-linear .progress-linear-bar{
    width: 120px !important;
    height: 8px;
    border-radius: 10px;
    display: flex;
}
.progress-linear .progress-linear-bar .partial{
  border-radius: 10px;
  height: 100%;
}
.progress-linear .progress-linear-bar .passed{
  background-color: #66BB6A;
  z-index: 10;
}
.progress-linear .progress-linear-bar .incomplete{
  background-color: #FFA726;
  position: relative;
  right: 5px;
  z-index: 9;
}
.progress-linear .progress-linear-bar .failed{
  background-color: #EF5350;
  position: relative;
  right: 10px;
  z-index: 8;
}
</style>
<style>
  .custom-progressbar{
    width: 200px;
  }
  .__attachment_progress{
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column !important;
    z-index: 999;
  }
</style>
